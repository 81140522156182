import Vue from 'vue'
import VueRouter from 'vue-router'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const loadView = (view) => {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: loadView('Home')
  },
  {
    path: '/estimation',
    name: 'estimation',
    component: loadView('EstimateResult'),
    meta: {
      requirePhone: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: loadView('AdminHome'),
    meta: {
      requireAdmin: true
    }
  },
  {
    path: '/admin/content',
    name: 'content',
    component: loadView('AdminContent'),
    meta: {
      requireAdmin: true
    }
  },
  {
    path: '/admin/documentation',
    name: 'documentation',
    component: loadView('AdminDocumentation'),
    meta: {
      requireAdmin: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  if (to.matched.some((record) => record.meta.requireAdmin)) {
    if (!currentUser || currentUser.uid !== '0myRwQ7F8ocUQvVmNlAZfBK0yu93') {
      next('/login')
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requirePhone)) {
    if (!currentUser.phoneNumber) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
