<template>
  <v-app-bar
    app
    color="#fff"
    light
  >
    <template v-if="isAdmin">
      <v-tabs
        v-model="activeTab"
      >
        <v-tab to="/admin">
          Tarifs
        </v-tab>
        <v-tab to="/admin/content">
          Contenu
        </v-tab>
        <v-tab to="/admin/documentation">
          Documentation
        </v-tab>
      </v-tabs>
      <v-btn
        icon
        @click="logout()"
      >
        <v-icon>{{ icons.mdiLogout }}</v-icon>
      </v-btn>
    </template>
    <div
      v-else
      class="d-flex align-center d-inline-flex justify-space-between"
      style="width:100%"
    >
      <a
        href="https://www.terrasse-en-ville.com/"
        target="_blank"
      >
        <v-img
          alt="Terrasse en Ville"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="140"
        />
      </a>
      <v-spacer />
      <a
        href="https://www.beaulieu-en-ville.com/"
        target="_blank"
      >
        <v-img
          alt="Beaulieu en Ville"
          src="@/assets/logo-beaulieu-258.jpeg"
          class="shrink"
          transition="scale-transition"
          width="140"
          contain
        />
      </a>
    </div>
  </v-app-bar>
</template>

<script>

import {
  mdiLogout
} from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  name: 'THeader',
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: '',
      icons: {
        mdiLogout
      }
    }
  },
  methods: {
    ...mapActions(['logout'])
  }

}
</script>
