import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'

import '@/helpers/filters'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_MEASUREMENT_ID },
  includes: [
    { id: 'AW-1037442492' }
  ]
}, router)

let app
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
