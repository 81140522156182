<template>
  <v-app>
    <t-header :is-admin="isAdmin" />
    <v-main>
      <router-view />
      <google-consent />
    </v-main>
    <t-footer v-if="!isAdmin" />
  </v-app>
</template>

<script>

import THeader from './components/Header.vue'
import TFooter from './components/Footer.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import { mapState, mapActions } from 'vuex'
import GoogleConsent from './components/GoogleConsent.vue'

export default {
  name: 'TerrasseEnVilleEstimationApp',
  components: {
    TFooter,
    THeader,
    GoogleConsent
  },
  data () {
    return {
      isAdmin: false
    }
  },
  computed: {
    ...mapState(['selectedContent'])
  },
  mounted () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.isAdmin = user.uid === '0myRwQ7F8ocUQvVmNlAZfBK0yu93'
      }
    })
    this.getContent()
  },
  methods: {
    ...mapActions(['findContent']),
    getContent () {
      const domainName = window.location.hostname
      let typeId = null
      if (domainName.includes('estimation-immobilier-pays-aix-en-provence.com')) {
        typeId = 'aix'
      } else if (domainName.includes('estimation-immobilier-la-ciotat-cassis-terrasse.com')) {
        typeId = 'cassis'
      } else if (domainName.includes('estimation-immobilier-espaces-atypiques.com')) {
        typeId = 'beaulieue'
      } else if (domainName.includes('estimation-immobilier-toulon-terrasse.com')) {
        typeId = 'cassis'
      } else if (domainName.includes('estimation-immobilier-montpellier-terrasse.com')) {
        typeId = 'montpellier'
      } else {
        typeId = 'marseille'
      }
      this.findContent(typeId)
    }
  }
}
</script>
