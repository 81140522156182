import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
  themes: {
    themes: {
      // primary: '#324e73',
      // secondary: '#5D2C51',
      light: {
        primary: '#324e73',
        secondary: '#5D2C51'
      },
      dark: {
        primary: '#324e73',
        secondary: '#5D2C51',
        background: '#5D2C51'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
}

export default new Vuetify(opts)
