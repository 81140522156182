<template>
  <v-footer
    dark
    class="py-5 mt-5"
    style="background-color:#5D2C51"
  >
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>MARSEILLE TERRE</strong><br>
            26 rue des trois frères Barthélémy<br>
            13006 Marseille<br>
            tél : 04 96 12 22 90<br>
            <a href="mailto:contact@terrasse-en-ville.com">
              contact@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>MARSEILLE MER</strong>
            <br>166 corniche Kennedy <br>
            13007 Marseille <br>
            tél : 04 91 52 16 21 <br>
            <a href="mailto:contact@terrasse-en-ville.com">
              contact@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>PAYS D’AIX</strong><br>
            50 rue cardinale <br>
            13100 Aix-en-provence <br>
            tél : 04 42 29 74 87 <br>
            <a href="mailto:aix@terrasse-en-ville.com">
              aix@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>LA CIOTAT</strong> <br>
            87 av du Maréchal Gallieni <br>
            13600 La Ciotat <br>
            tél : 04 13 92 05 30 <br>
            <a href="mailto:laciotat@terrasse-en-ville.com">
              laciotat@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>TOULON</strong> <br>
            42 rue Lamalgue<br>
            83000 Toulon<br>
            Tél : 04 22 80 21 62<br>
            <a href="mailto:toulon@terrasse-en-ville.com">
              toulon@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>MARSEILLE MONTOLIVET</strong> <br>
            4 bd Bigonnet<br>
            13012 Marseille<br>
            <a href="mailto:montolivet@terrasse-en-ville.com">
              montolivet@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          tile
          class="white--text text-center"
          style="background-color:#5D2C51"
        >
          <v-card-text>
            <strong>MONTPELLIER</strong> <br>
            <a href="montpellier@terrasse-en-ville.com">
              montpellier@terrasse-en-ville.com
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      flat
      tile
      class="white--text text-center"
      width="100%"
      style="background-color:#5D2C51"
    >
      <v-divider />
      <v-card-text>
        Location : 04 96 12 22 91 / Gestion : 04 96 12 22 95 / Syndic : 04 96 12 22 96
        <br>
        <a href="mailto:location@terrasse-en-ville.com">location@terrasse-en-ville.com</a>
        /
        <a href="mailto:gestion@terrasse-en-ville.com">gestion@terrasse-en-ville.com</a>
        /
        <a href="mailto:syndic@terrasse-en-ville.com">syndic@terrasse-en-ville.com</a>
        <br>
        <a href="https://www.terrasse-en-ville.com/mentions-legales"> Mention Légale</a>
        <br>
        <a href="https://www.terrasse-en-ville.com/blog-marseille">MAG MARSEILLE</a> / <a href="https://www.terrasse-en-ville.com/blog-la-ciotadenne-la-ciotat">MAG LA CIOTAT</a> / <a href="https://www.terrasse-en-ville.com/bolg-aix-en-provence">MAG AIX</a>
      </v-card-text>
      <v-divider />
      <v-card-text>
        <a
          href="https://twitter.com/marsenville"
          target="_blank"
          class="mr-4"
        >
          <v-icon>{{ icons.mdiTwitter }}</v-icon>
        </a>
        <a
          href="https://www.linkedin.com/in/stanislas-brunet-a0256512a/"
          target="_blank"
          class="mr-4"
        >
          <v-icon>{{ icons.mdiLinkedin }}</v-icon>
        </a>
        <a
          href="https://www.facebook.com/marsenville/"
          target="_blank"
          class="mr-4"
        >
          <v-icon>{{ icons.mdiFacebook }}</v-icon>
        </a>
        <a
          href="https://www.instagram.com/terrasse_en_ville/"
          target="_blank"
          class="mr-4"
        >
          <v-icon>{{ icons.mdiInstagram }}</v-icon>
        </a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import {
  mdiFacebook, mdiTwitter, mdiInstagram, mdiLinkedin
} from '@mdi/js'

export default {
  name: 'TFooter',
  data () {
    return {
      icons: {
        mdiFacebook,
        mdiTwitter,
        mdiInstagram,
        mdiLinkedin
      }
    }
  }

}
</script>

<style>
  footer a {
    color: #fff!important;
    text-decoration: none;
    opacity: 0.7;
  }
  footer a:hover {
    opacity: 0.5;
  }
</style>
