import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import router from '../router/index'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import { db } from '@/db'
import axios from 'axios'
import * as turf from '@turf/turf'
import emailjs from 'emailjs-com'

Vue.use(Vuex)

const dbPrices = db.collection('prices')
const dbContent = db.collection('content')

const redoState = () => ({
  addressList: [],
  estimate: {
    geoAddress: {},
    type: null,
    condition: null,
    seniority: null,
    surface: null,
    view: false,
    pool: false,
    exterior: null,
    quiet: null,
    sunshine: null,
    opposite: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null
  }
})

const featureState = () => ({
  id: Date.now() + Math.random(),
  createdAt: Date.now(),
  properties: {
    name: null,
    city: null,
    price_u30: 0,
    price_u50: 0,
    price_u70: 0,
    price_u100: 0,
    price_u200: 0,
    price_p200: 0,
    maison: 0,
    vue: 0,
    sansExterieur: 0,
    editGeo: true
  },
  geometry: {
    type: 'Polygon',
    coordinates: []
  },
  type: 'Feature'
})

export default new Vuex.Store({
  strict: true,
  state: {
    user: null,
    rawPrices: [],
    content: [],
    selectedContent: {},
    addressList: [],
    selectedPrice: {},
    cities: ['Marseille', 'Aix-en-Provence', 'Toulon', 'La Ciotat', 'Cassis', 'Montpellier'],
    instanceCity: '',
    isLoading: {
      search: false,
      email: true,
      content: true
    },
    error: {
      search: false,
      email: false
    },
    estimate: redoState().estimate,
    editFeature: featureState(),
    estimateTest: {
      type: 'house',
      condition: 0,
      seniority: 0,
      surface: 100,
      view: false,
      pool: false,
      exterior: 0,
      quiet: 0,
      sunshine: 0,
      opposite: 0
    },
    options: {
      type: [
        {
          label: 'Maison',
          value: 'house'
        },
        {
          label: 'Appartement',
          value: 'apartment'
        }
      ],
      condition: [
        {
          label: 'Neuf',
          value: 0
        },
        {
          label: 'À rénover',
          value: -1000
        },
        {
          label: 'À rafraîchir',
          value: -500
        }
      ],
      seniority: [
        {
          label: 'Ancien (avant 1959)',
          value: 0
        },
        {
          label: 'Semi-récent (1960-1979)',
          value: -700
        },
        {
          label: 'Récent (1980 - 1999)',
          value: -400
        },
        {
          label: 'Contemporain',
          value: -200
        }
      ],
      exterior: [
        {
          label: 'Sans extérieur',
          value: -1
        },
        {
          label: 'Balcon',
          value: -500
        },
        {
          label: 'Terrasse',
          value: 0
        },
        {
          label: 'Jardin',
          value: 100
        },
        {
          label: 'Parc (supérieur à 1000m2)',
          value: 400
        }
      ],
      quiet: [
        {
          label: 'Bien',
          value: 0
        },
        {
          label: 'Moyen',
          value: -200
        },
        {
          label: 'Passable',
          value: -600
        }
      ],
      sunshine: [
        {
          label: 'Bien',
          value: 0
        },
        {
          label: 'Moyen',
          value: -200
        },
        {
          label: 'Passable',
          value: -600
        }
      ],
      opposite: [
        {
          label: 'Bien',
          value: 0
        },
        {
          label: 'Moyen',
          value: -200
        },
        {
          label: 'Passable',
          value: -600
        }
      ],
      typeUser: ['Vendeur', 'Acheteur', 'Vendeur et acheteur', 'Visiteur simple']
    },
    dialog: {
      phoneValidation: false
    }
  },
  mutations: {
    ...vuexfireMutations,
    SET_SELECTED_PRICE (state, data) {
      state.selectedPrice = data
    },
    SET_LOADING (state, payload) {
      state.isLoading[payload.key] = payload.value
    },
    SET_DIALOG (state, payload) {
      state.dialog[payload.key] = payload.value
    },
    SET_ERROR (state, payload) {
      state.error[payload.key] = payload.value
    },
    SET_ADDRESS_LIST (state, payload) {
      state.addressList = payload
    },
    SET_SELECTED_CONTENT (state, payload) {
      state.selectedContent = payload
    },
    SET_STATE_ESTIMATE (state, payload) {
      if (payload.type === 'test') {
        state.estimateTest[payload.key] = payload.value
      } else {
        state.estimate[payload.key] = payload.value
      }
    },
    RESET_ESTIMATE (state) {
      Object.assign(state, redoState())
    },
    SET_STATE_EDIT_FEATURE (state, payload) {
      state.editFeature.properties[payload.key] = payload.value
    },
    SET_EDIT_FEATURE (state, payload) {
      Object.assign(state.editFeature, payload)
    },
    SET_EDIT_GEOMETRY_FEATURE (state, payload) {
      Object.assign(state.editFeature.geometry, payload)
    },
    SET_NEW_FEATURE (state) {
      Object.assign(state.editFeature, featureState())
    },
    UPDATE_POLYGON (state, payload) {
      state.editFeature.geometry.coordinates[0] = payload
    },
    SET_INSTANCE_CITY (state, payload) {
      state.instanceCity = payload
    }
  },
  actions: {
    bindPrices: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('rawPrices', dbPrices)
    }),
    bindContent: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('content', dbContent)
    }),
    addPrice: firestoreAction(({ state }) => {
      dbPrices
        .add({ data: JSON.stringify(state.editFeature) })
    }),
    updateFeature: firestoreAction(({ state }) => {
      return dbPrices
        .doc(state.editFeature.id)
        .update({ data: JSON.stringify(state.editFeature) })
    }),
    removeFeature: firestoreAction((layerId) => {
      dbPrices
        .doc(layerId)
        .delete()
    }),
    updateContent: firestoreAction((_, payload) => {
      return dbContent
        .doc(payload.id)
        .update(payload.data)
    }),
    findContent: firestoreAction(({ commit }, payload) => {
      commit('SET_LOADING', { key: 'content', value: true })
      commit('SET_INSTANCE_CITY', payload)
      return dbContent
        .where('type', '==', payload)
        .get()
        .then((response) => {
          commit('SET_SELECTED_CONTENT', response.docs[0].data())
        })
        .finally(() => {
          commit('SET_LOADING', { key: 'content', value: false })
        })
    }),
    updateSelectedPrice ({ commit }, payload) {
      commit('SET_SELECTED_PRICE', payload)
    },
    async login (context, form) {
      await firebase.auth().signInWithEmailAndPassword(form.email, form.password)
      await router.push('admin')
    },
    async logout () {
      await firebase.auth().signOut()
      await router.replace('/')
    },
    searchAddress ({ commit, state }, search) {
      if (!search) return
      if (state.isLoading.search) return

      commit('SET_LOADING', { key: 'search', value: true })
      commit('SET_ERROR', { key: 'search', value: null })

      axios
        .get('https://api-adresse.data.gouv.fr/search/?q=' + search)
        .then((res) => {
          commit('SET_ADDRESS_LIST', res.data.features)
        })
        .catch(() => {
          commit('SET_ERROR', { key: 'search', value: 'Service indisponible, veuillez réessayer dans quelques minutes' })
        })
        .finally(() => {
          commit('SET_LOADING', { key: 'search', value: false })
        })
    },
    async setPriceReference ({ dispatch, commit, getters }, geoAddress) {
      if (!geoAddress) return
      await dispatch('bindPrices')
      const filteredReferenceList = getters.pricesParsed
        .filter((price) => {
          const pt = geoAddress.geometry.coordinates
          const poly = price.properties.editGeo ? [price.geometry.coordinates] : price.geometry.coordinates
          return turf.booleanPointInPolygon(pt, poly)
        })
        .reduce((acc, i) => {
          const area = turf.area(i.geometry)
          acc.push({ ...i, area })
          return acc
        }, [])
        .sort((a, b) => a.area - b.area)
      console.log(filteredReferenceList)
      if (!filteredReferenceList.length) {
        commit('SET_ERROR', { key: 'search', value: 'Nous ne pouvons pas estimer ce bien' })
      } else {
        dispatch('updateSelectedPrice', filteredReferenceList[0])
      }
    },
    async sendEmail ({ commit, state, getters }) {
      commit('SET_LOADING', { key: 'email', value: true })
      await emailjs.send('gmail', 'template_hjdc1s9', getters.estimateResult(state.selectedPrice, state.estimate), process.env.VUE_APP_EMAILJS_ID)
        .catch(() => {
          commit('SET_ERROR', { key: 'email', value: 'Impossible d\'envoyer votre résultat d\'estimation. Avez-vous renseigné un email valide ?' })
        })
        .finally(() => {
          commit('SET_LOADING', { key: 'email', value: false })
        })
    },
    setDialog ({ commit }, payload) {
      commit('SET_DIALOG', payload)
    },
    setStateEstimate ({ commit, dispatch }, payload) {
      commit('SET_STATE_ESTIMATE', payload)
      if (payload.key === 'geoAddress' && payload.value) {
        dispatch('setPriceReference', payload.value)
      }
    },
    setStateEditFeature ({ commit }, payload) {
      commit('SET_STATE_EDIT_FEATURE', payload)
    },
    setEditFeature ({ commit }, payload) {
      commit('SET_EDIT_FEATURE', payload)
    },
    resetEstimate ({ commit }) {
      commit('RESET_ESTIMATE')
    },
    setNewFeature ({ commit }) {
      commit('SET_NEW_FEATURE')
    },
    updatePolygon ({ commit }, payload) {
      commit('UPDATE_POLYGON', payload)
    },
    setEditFeatureGeometry ({ commit }, payload) {
      commit('SET_EDIT_FEATURE_GEOMETRY', payload)
    }
  },
  getters: {
    pricesParsed: (state) => {
      return state.rawPrices
        .map((x) => ({
          ...JSON.parse(x.data),
          id: x.id
        }))
        .sort((a, b) => b.properties.name < a.properties.name ? 1 : -1)
    },
    isReference: (state) => {
      return !!state.selectedPrice.id
    },
    estimateResult: (state) => (reference, estimate) => {
      // Calcul des options
      let estimateOptions = 0

      if (estimate.type === 'house') {
        estimateOptions += +reference.properties.maison
      }
      if (estimate.view) {
        estimateOptions += +reference.properties.vue
      }
      if (estimate.condition) {
        estimateOptions += estimate.condition
      }
      if (estimate.seniority) {
        estimateOptions += estimate.seniority
      }
      // Sans extérieur est une variable bdd
      if (estimate.exterior && estimate.exterior !== -1) {
        estimateOptions += estimate.exterior
      }
      if (estimate.exterior === -1) {
        estimateOptions += +reference.properties.sansExterieur
      }
      if (estimate.quiet) {
        estimateOptions += estimate.quiet
      }
      if (estimate.sunshine) {
        estimateOptions += estimate.sunshine
      }
      if (estimate.opposite) {
        estimateOptions += estimate.opposite
      }
      // Gestion des options négatives
      // if (estimateOptions < 0) estimateOptions = 0

      let referenceKey
      switch (true) {
        case (estimate.surface <= 30):
          referenceKey = 'price_u30'
          break
        case (estimate.surface <= 50):
          referenceKey = 'price_u50'
          break
        case (estimate.surface <= 70):
          referenceKey = 'price_u70'
          break
        case (estimate.surface <= 100):
          referenceKey = 'price_u100'
          break
        case (estimate.surface <= 200):
          referenceKey = 'price_u200'
          break
        case (estimate.surface > 200):
          referenceKey = 'price_p200'
          break
      }
      // Les options ne peuvent pas être inférieurs à 1500 euros le m2
      const finalOptions = estimateOptions + +reference.properties[referenceKey] > 1500
        ? estimateOptions + +reference.properties[referenceKey]
        : 1500

      let total = +estimate.surface * finalOptions

      // Si il y a une piscine, on ajoute 15 000 euros au total
      if (estimate.pool) {
        total += 15000
      }

      return {
        ...estimate,
        prixMoyen: Math.round(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €',
        prixBas: Math.round(total * 0.9).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €',
        prixHaut: Math.round(total * 1.1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €',
        labelType: estimate.type === 'house' ? 'Maison' : 'Appartement',
        labelPool: estimate.pool ? 'Oui' : 'Non',
        labelView: estimate.view ? 'Oui' : 'Non',
        labelCondition: state.options.condition.find((x) => x.value === estimate.condition)?.label,
        labelSeniority: state.options.seniority.find((x) => x.value === estimate.seniority)?.label,
        labelOpposite: state.options.opposite.find((x) => x.value === estimate.opposite)?.label,
        labelSunshine: state.options.sunshine.find((x) => x.value === estimate.sunshine)?.label,
        labelQuiet: state.options.quiet.find((x) => x.value === estimate.quiet)?.label,
        labelExterior: state.options.exterior.find((x) => x.value === estimate.exterior)?.label,
        labelZone: reference.properties.name,
        emailBcc: state.instanceCity === 'montpellier' ? 'montpellier@terrasse-en-ville.com' : 'estimation.terrasse@gmail.com'
      }
    }
  }
})
